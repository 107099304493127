import { first } from 'rxjs/operators';
import { Contact } from '../contact/contact';
import { AlertService } from '../../components/alert/alert.svc';
import { ContactsData } from '../contacts.data';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ServicesData } from '../../services/services.data';


@Component({
  selector: 'contacts-select-pick',
  templateUrl: './contacts-select-pick.tmpl.html',
  styleUrls: ['./contacts-select-pick.scss']
})

export class ContactsSelectPick implements OnInit {

  constructor(
    private servicesData: ServicesData,
    public dialog: MatDialog
  ) {

  }

  @Input('contactID') public contactID: number;
  @Input('onlyOpenContacts') public onlyOpenContacts: boolean = true;
  @Input('contactIdControl') public contactIdControl: FormControl;
  @Input('contactNameControl') public contactNameControl: FormControl;
  @Input('formGroupModel') public formGroupModel: FormGroup;
  @Input('placeHolder') public placeHolder: string;
  @Input('withLabel') public withLabel: boolean = true;
  @Input('withSubTitle') public withSubTitle: boolean = false;
  @Input('emitFullObject') public emitFullObject: boolean = false;


  @Output() contactAdd = new EventEmitter<Contact>();
  @Output() contactChange = new EventEmitter<Contact>();
  @Output() contactSelect = new EventEmitter<Contact>();


  public selectedContact: Contact;
  public contacts;
  public getContacts(): void {
    let cid = this.contactIdControl && this.contactIdControl.value > 0 ? this.contactIdControl.value : null;

    this.servicesData.getList(`Contacts?query=${cid}&rowsCount=${20}`).subscribe(
      c => {
        this.contacts = c;
      },
      error => { });
  }

  openAddOrEditContact() {
    // console.log("selectedContact",this.selectedContact)  ;
  }
  contactAddEvent(contact) {
    this.contacts = null;
    this.getContacts();
    if (contact && contact.id > 0) {
      this.contactIdControl.setValue(contact.id);
    }
    this.contactAdd.emit(contact);
  }

  contactChangeEvent(contact) {
    this.contacts = null;
    this.getContacts();
    let id = contact.id || contact.contactID;
    if (contact && id > 0) {
      this.contactIdControl.setValue(id);
      this.contactNameControl.setValue(contact.lastName + ' ' + contact.firstName);
    }
    this.contactChange.emit(contact);
  }

  contactSelectEvent(e) {
    console.log(e);
    this.contactSelect.emit(this.selectedContact);
  }

  // private setContactIDValue() {
  //     if(this.contactIdControl.value){
  //         this.selectedContact = this.contacts.find(x => x.contactID == this.contactIdControl.value);
  //     }
  //     //this.contactIdControl.setValue(contactID);
  // }



  ngOnInit() {

    this.contactIdControl.valueChanges.subscribe(x => {
      if (this.contacts) {
        this.selectedContact = this.contacts.find(x => x.contactID == this.contactIdControl.value);
        this.contactSelect.emit(this.selectedContact); //מחליף את האירוע contactSelectEvent
      }
    });
    this.getContacts();
  }
}



