import { TishTableDlg } from './tishTable-edit-dlg.cmp';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { TishInvited } from './../../tishInviteds/tishInvited/tishInvited';
import { TishInvitedsData } from './../../tishInviteds/tishInviteds.data';
import { TishPlaceDlg } from './../../tishPlaces/tishPlace/tishPlace-edit-dlg.cmp';
import { TishPlace } from './../../tishPlaces/tishPlace/tishPlace';
import { TishTablesData } from './../tishTables.data';
import { TishPlacesData } from './../../tishPlaces/tishPlaces.data';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { TishTable } from './tishTable';
import { FormGroup, FormArray } from '@angular/forms';
declare var jQuery: any;

@Component({

    selector: 'tishTable-map',
    templateUrl: './tishTable-map.tmpl.html',
    styleUrls: ['./../tishTables.scss']
})

export class TishTableMap implements OnInit, AfterViewInit { //, AfterViewInit


    constructor(
        private tishTablesData: TishTablesData,
        private tishPlacesData: TishPlacesData,
        private tishInvitedsData: TishInvitedsData,
        private toraxHttp: ToraxHttp,
        public dialog: MatDialog,
    ) {
        this.toraxHttp.updatedEvent.subscribe((id) => {
            if (id > 0 && this.tishTable && id === this.tishTable.tishTableID) {
                this.load();
            }
        });
    }
    @Input('tishEventForm') public tishEventForm: FormGroup;
    @Input('ix') public ix: number;
    @Input('tishTableID') public tishTableID: number;
    @Input('searchTishTableName') public searchTishTableName: string = "";
    @Input('searchTishPlaceNumber') public searchTishPlaceNumber: number = null;

    @Output() onStartTableDragging = new EventEmitter<any>();
    @Output() onStartInvitedDragging = new EventEmitter<any>();
    @Output() onDropedElement = new EventEmitter<any>();

    getPosition(e) {
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        return {
            x,
            y
        }
    }
    tishTableForm: FormGroup;
    get tishPlaces(): FormGroup {
        return this.tishTableForm.controls['tishPlaces'] as FormGroup;

    }
    onDragInvited(e: boolean) {
        this.onStartInvitedDragging.emit(e);
    }

    onDropedElementFn(e: boolean) {
        this.onDropedElement.emit(e);
    }



    addPlaceDlg() {
      alert("לא פעיל, כעת ניתן להוסיף מקומות דרך הלחצן עריכת פרטי שולחן");
        // let tp = new TishPlace();
        // tp.tishTableID = this.tishTable.tishTableID;
        // tp.tishTableName = this.tishTable.tishTableName;
        // tp.narrowTable = this.tishTable.narrowTable;


        // setTimeout(() => {
        //     const dialogRef = this.dialog.open(TishPlaceDlg, {
        //         data: tp
        //     });
        //     dialogRef.afterClosed().subscribe(result => {
        //         if (result && result.valid) {
        //             this.createTishPlace(result);
        //         }
        //     });
        // }, 200);

    }

    createTishPlace = (formData: FormGroup): void => {
        if (formData.valid) {
            var tpData = this.tishPlacesData;
            var ttFrm = this.tishTableForm;
            var tt = this.tishTable;
            this.tishPlacesData.update(<TishPlace>formData.value)
                .subscribe((x: any) => {
                    this.tishPlacesData.getEdit(x.value)
                        .subscribe(tp => {
                            const tishPlacesControls = <any>ttFrm.controls['tishPlaces'];
                            let pFrm = tpData.initForm();
                            pFrm.removeControl("tishInvited");//TODO

                            pFrm.patchValue(tp);
                            let tpVal: any = pFrm.value;
                            tt.tishPlaces.push(tpVal);
                            tishPlacesControls.push(pFrm);
                            tt.countPlaces += 1;
                            ttFrm.controls['countPlaces'].patchValue(tt.countPlaces);
                        });
                });
        }
    }

    deleteTishPlace = (tpDlg: TishPlaceDlg): void => {
        if (tpDlg.formData.valid) {
            this.tishPlacesData.delete(tpDlg.formData.value.TishTableID).subscribe((c: any) => {
                return true;
            })
        }
    }

    editTableDlg() {
        let tt = this.tishTable;
        tt.countPlaces = this.tishTable.countPlaces;

        setTimeout(() => {
            const dialogRef = this.dialog.open(TishTableDlg, {
                data: tt
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result && result.valid) {
                    this.saveTishTable(result);
                }
            });
        }, 200);
    }



    saveTishTable = (formData: FormGroup): void => {
        if (formData.valid) {
            this.tishTable = formData.value;
            this.tishTableForm = formData;
            this.save();
        }
    }

    isLock;
    left: string;
    top: string;
    transform: string;
    id: number = 0;
    tishEventID: number;
    tishTable: TishTable;
    visibleToolsPanel = false;


    rotatingObject(e: any) {
        let startDraggingEvnt = this.onStartTableDragging;
        var tishTablesData = this.tishTablesData;
        var tishTableForm = this.tishTableForm;

        let btn = jQuery(e.target);
        // if (btn.hasClass("fa-lock")) {

        //  btn.removeClass('fa-lock');
        this.visibleToolsPanel = true;

        //  btn.addClass('fa-unlock-alt');
        var tableElm = btn.parent("div").parent("div.draggable_wp");
        // var div = tableElm.find("div.tools-panel");
        // div.show();

        var mousePosition;
        var offset = [0, 0];

        var rotating = false,
            dragging = false,
            o_x, o_y, h_x, h_y, last_angle;

        let rotatingHand = tableElm.find('.rotatingHandle');
        let draggingHandle = tableElm.find('.draggingHandle');
        let onDrpdElmnt = this.onDropedElement;
        var stopDragging = function (event) {
            rotating = false;
            dragging = false;
            $(event.target).unbind("mousemove");
            onDrpdElmnt.emit(true);
        }
        let getPos = this.getPosition;
        let canvas: any = document.getElementById("canvas");
        //עצירת האירועים
        canvas.addEventListener("mouseup", stopDragging, true);

        canvas.addEventListener('mousemove', function (event) {
            event.preventDefault();
            if (dragging) {
                let x = 0;
                let y = 0;

                var xPx = `${event.clientX}px`;
                var yPx = `${event.clientY}px`;

                tableElm.css("left", xPx);
                tableElm.css("top", yPx);

                tishTableForm.controls.tishTableLeft.setValue(xPx);
                tishTableForm.controls.tishTableTop.setValue(yPx);
            }
            else if (rotating) {
                var s_x = event.pageX,
                    s_y = event.pageY; // start rotate point
                if (s_x !== o_x && s_y !== o_y) { //start rotate
                    var s_rad = Math.atan2(s_y - o_y, s_x - o_x); // current to origin
                    s_rad -= Math.atan2(h_y - o_y, h_x - o_x); // handle to origin
                    s_rad += last_angle; // relative to the last one
                    var degree = (s_rad * (360 / (2 * Math.PI)));
                    tableElm.css('-moz-transform', 'rotate(' + degree + 'deg)');
                    tableElm.css('-moz-transform-origin', '50% 50%');
                    tableElm.css('-webkit-transform', 'rotate(' + degree + 'deg)');
                    tableElm.css('-webkit-transform-origin', '50% 50%');
                    tableElm.css('-o-transform', 'rotate(' + degree + 'deg)');
                    tableElm.css('-o-transform-origin', '50% 50%');
                    tableElm.css('-ms-transform', 'rotate(' + degree + 'deg)');
                    tableElm.css('-ms-transform-origin', '50% 50%');
                    tishTableForm.controls.transformRotate.setValue(`rotate(${degree}deg)`);
                }
            }
        }, true);


        draggingHandle.mousedown(function ($event) {
            startDraggingEvnt.emit($event);
            rotating = false;
            dragging = true;
        });

        rotatingHand.mousedown(function (event) {
            dragging = false;
            rotating = true;

            h_x = event.pageX;
            h_y = event.pageY; // clicked point
            event.preventDefault();
            event.stopPropagation();
            // target_wp = $(event.target).closest('.draggable_wp');
            if (!tableElm.data("origin")) tableElm.data("origin", {
                left: tableElm.offset().left,
                top: tableElm.offset().top
            });
            o_x = tableElm.data("origin").left;
            o_y = tableElm.data("origin").top; // origin point

            last_angle = tableElm.data("last_angle") || 0;
        });
    }

    goLeft(e) {
        let x = +this.tishTable.tishTableLeft.replace("px", "");
        let nx = (x-5)
        this.tishTable.tishTableLeft = `${nx}px`;
        this.tishTableForm.controls["tishTableLeft"].setValue(this.tishTable.tishTableLeft);

        let btn = jQuery(e.target);
        var tableElm = btn.parent("div").parent("div.draggable_wp");
        tableElm.css("left", nx);
    }


    goRight(e) {
        let x = +this.tishTable.tishTableLeft.replace("px", "");
        let nx = (x+5)
        this.tishTable.tishTableLeft = `${nx}px`;
        this.tishTableForm.controls["tishTableLeft"].setValue(this.tishTable.tishTableLeft);

        let btn = jQuery(e.target);
        var tableElm = btn.parent("div").parent("div.draggable_wp");
        tableElm.css("left", nx);
    }


    goUp(e) {
        let x = +this.tishTable.tishTableTop.replace("px", "");
        let nx = (x-5)
        this.tishTable.tishTableTop = `${nx}px`;
        this.tishTableForm.controls["tishTableTop"].setValue(this.tishTable.tishTableTop);

        let btn = jQuery(e.target);
        var tableElm = btn.parent("div").parent("div.draggable_wp");
        tableElm.css("top", nx);
    }

    goDown(e) {
        let x = +this.tishTable.tishTableTop.replace("px", "");
        let nx = (x+5)
        this.tishTable.tishTableTop = `${nx}px`;
        this.tishTableForm.controls["tishTableTop"].setValue(this.tishTable.tishTableTop);

        let btn = jQuery(e.target);
        var tableElm = btn.parent("div").parent("div.draggable_wp");
        tableElm.css("top", nx);
    }


    public load(): void {
        this.tishTablesData.getMap(this.id)
            .subscribe((tg: any) => {
                this.tishTable = tg;
                this.left = tg.tishTableLeft;
                this.top = tg.tishTableTop;
                this.transform = tg.transformRotate;

                const tishPlacesControls = <FormArray>this.tishTableForm.controls['tishPlaces'];
                var len = tishPlacesControls.length;
                for (var i = 0; i < len; i++) {
                    tishPlacesControls.removeAt(0);
                }
                (<TishTable>this.tishTable).tishPlaces.forEach(place => {
                    if (place != null) {
                        let plceFrm = this.tishPlacesData.initForm();
                        if (place.tishInvited == null) {
                            place.tishInvited = new TishInvited();
                            place.tishInvited.tishEventID = this.tishEventForm.value.tishEventID;
                        }
                        else {
                            //הוספת Form מוזמן למקום
                        }
                        let plc :any = place;
                        plceFrm.patchValue(plc);
                        plceFrm.controls["tishInvited"] = this.tishInvitedsData.initForm();
                        plceFrm.controls["tishInvited"].patchValue(plc.tishInvited);
                        plceFrm.controls["tishInvitedID"].patchValue(plc.tishInvited.tishInvitedID || 0);
                        plceFrm.controls["areaColor"].patchValue(plc.areaColor);

                        //
                        tishPlacesControls.push(plceFrm);
                    }
                });
                this.tishTableForm.patchValue(this.tishTable);
            });
    }

    ngOnInit() {
        this.id = this.tishTableID;
        if (this.id) {
            this.tishTableForm = this.tishTablesData.initForm();
            this.load();
        }
    }

    public save() {
        if (this.tishTableForm.valid) {
            this.tishTablesData.update(this.tishTableForm.value)
                .subscribe((c: any) => {
                    this.id = c.id;
                    if (this.id) {
                      this.tishTableForm = this.tishTablesData.initForm();
                      this.load();
                  }
                });
        } else {
            console.log('not valid');
            console.log(this.tishTableForm.errors);
        }
    }

    ngAfterViewInit() {
        this.visibleToolsPanel = false;
    }

}
