<div fxLayout="column" [formGroup]="tishTableForm" class="draggable_wp"
    [ngStyle]="{'width': (tishTableForm.controls.narrowTable.value) ? '65px' : '75px'}" style="position: relative; z-index: 20px !important;"
    [style.left]="left" [style.top]="top" [style.transform]="transform">
    <div [hidden]="!visibleToolsPanel"  style="white-space: nowrap;text-align: center;" class="tools-panel hidden-print" >
        <i class="fa fa-arrow-left" (click)="goLeft($event)"></i>
        <i class="fa fa-arrow-down" (click)="goDown($event)"></i>
        <i class="fa fa-arrow-up" (click)="goUp($event)"></i>
        <i class="fa fa-arrow-right " (click)="goRight($event)"></i>
     </div>
    <div fxLayout="row" style="margin: 0;padding: 0;background: #39387929;">
        <span fxFlex class="table-name">{{tishTableForm.controls.tishTableName.value}}</span>
        <i class="actionIcon fa fa-lock hidden-print" *ngIf="!visibleToolsPanel"
            (click)="visibleToolsPanel = !visibleToolsPanel; rotatingObject($event)"></i>
        <i class="actionIcon fa fa-unlock-alt hidden-print" *ngIf="visibleToolsPanel"
            (click)="visibleToolsPanel = !visibleToolsPanel"></i>


        <i class="actionIcon fa fa-save hidden-print" (click)="save()"></i>
    </div>
    <div  [hidden]="!visibleToolsPanel"  class="tools-panel row"
        style="margin: 0;padding: 0; background: rgb(158, 207, 247)">
        <i class="actionIcon fa fa-plus" (click)="addPlaceDlg()"></i>
        <i class="actionIcon fa fa-pencil" (click)="editTableDlg()"></i>
        <i class="actionIcon fa fa-rotate-right rotatingHandle"></i>


        <!-- <i class="actionIcon fa fa-arrows-alt draggingHandle"></i> -->
    </div>
    <!-- <div style="width: 150px; height: 150px;" *ngIf=""></div> -->
    <div class="el" style="height: 600px;" [ngStyle]="{'box-shadow': (tishTableForm.controls.tishTableName.value == searchTishTableName ? '1px 0px 20px 20px #f7d304e7;' : '')}">
        <div *ngFor="let tpForm of tishPlaces.controls" class="placeContainer"
            style="margin: 0; padding: 0; border: 0px;"
            [ngStyle]="{'float': (tishTableForm.controls.narrowTable.value == false && tpForm.controls.placeNumber.value % 2) == 0 ? 'right' : 'left'}">


      <!-- <tishPlace-map *ngIf="tpForm.controls.isMarginActive.value == true" [isMarginActive]="tpForm.controls.isMarginActive.value" [marginType]="tpForm.controls.marginType.value" [narrowTable]="tishTableForm.controls.narrowTable.value" [tishPlaceForm]="tpForm"> </tishPlace-map> -->
     <tishPlace-map [narrowTable]="tishTableForm.controls.narrowTable.value" [tishEventID]="tishTableForm.controls.tishEventID.value" (dragInvited)="onDragInvited($event)" (dropedElement)="onDropedElementFn($event)"  [searchTishPlaceNumber]="(tishTableForm.controls.tishTableName.value == searchTishTableName ? searchTishPlaceNumber : null)" [tishPlaceForm]="tpForm"></tishPlace-map>
        </div>
    </div>
</div>
