import { Injectable, EventEmitter } from '@angular/core';
import { TishInvited } from './tishInvited/tishInvited';
import { ToraxHttp } from '../utils/custom-http.svc';
import { Validators, FormBuilder } from '@angular/forms';


@Injectable()

export class TishInvitedsData {
  getTishGroups(tishEventID: number) {
    throw new Error('Method not implemented.');
  }


  private url = 'api/tishInviteds';

  constructor(
    private toraxHttp: ToraxHttp,
    private formBuilder: FormBuilder,
  ) { }

  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }

  deleteAnyway(id: number, anyway: boolean = false) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/deleteAnyway/${id}/${anyway}`);
  }



  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }
  getViewList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/viewList`, baseQuery);
  }

  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }

  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  update(TishInvited: TishInvited) {
    return this.toraxHttp.update(this.url, TishInvited);
  }

  getAdd(tishEventID: number) {
    return this.toraxHttp.get(`${this.url}/add/${tishEventID || 0}`);
  }

  getAddInvited(tishEventID: number, contactID: number) {
    return this.toraxHttp.get(`${this.url}/addInvited/${tishEventID || 0}/${contactID || 0}`);
  }

  detachInvited(invitedID: number) {
    return this.toraxHttp.postWithResponce(`${this.url}/detachInvited/${invitedID || 0}`, null);
  }

  exportExcel(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
  }

  // downloadTicketsExls(baseQuery: any){
  //     return this.toraxHttp.post(`${this.url}/downloadTicketsExls`, baseQuery);
  // }



  public initForm() {
    return this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      tishInvitedID: [0, Validators.required],
      contactID: [0, Validators.required],
      tishInvitedName: [],
      hostInvitedID:[],
      hostContactName:[],
      tishGroupID: [],
      tishGroupName: [],
      tishAreaID: [],
      tishAreaName: [],
      tishEventID: [, Validators.required],
      tishEventTitle: [],
      perfix: [],
      firstName: [],
      lastName: [],
      suffix: [],
      isPlace: [true, Validators.required],
      invitedStatus: [],
      specialInvitedPerfix: [],
      specialInvitedFirstName: [],
      specialInvitedLastName: [],
      specialInvitedSuffix: [],
      ticketName: [],
      comment: [],
      notEmbedded: [],
      isChild:[false, Validators.required],
      doSendletter:[false, Validators.required],
      invitedConfirmed:[true, Validators.required],
      quantityAdditionalTickets: [null],
      tishInviteds: this.formBuilder.array([]),
    });

  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }

}
