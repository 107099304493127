export class TishInvited {
  tishInvitedID: number;
  contactID: number;
  tishEventID: number;
  tishEventTitle;
  specialInvitedName;
  quantityAdditionalTickets;
  specialInvitedSuffix: string;
  specialInvitedLastName;
  specialInvitedFirstName;
  specialInvitedPerfix: string;
  invitedStatus: string;
  isPlace;
  //hostContactID;
  hostInvitedID;
  tishGroupID;
  tishAreaID;
  isChild;
}

