<form [formGroup]="tishInvitedForm" novalidate (ngSubmit)="save()" *ngIf="tishInvited">
  <div class="modal-header">
    <div class="form-row" fxLayout="row" fxLayout.xs="column">
      <h1>עריכת פרטי מוזמן [{{ (id > 0 ? id : 'חדש') }}]</h1>

      <span class="example-fill-remaining-space"></span>
      <h2 style="background-color: white; padding: 6px; margin: 10px;border: solid gray 1px;"><b>{{
          tishInvited.tishEventTitle }}</b> </h2>
    </div>

  </div>
  <div class="modal-body">
    <div class="example-form">
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי מוזמן
            <small style="color:blue"
              *ngIf="tishInvited.invitedStatus == 'ילד'">&nbsp;<u>{{tishInvited.invitedStatus}}</u></small>
          </legend>

          <!-- <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3" style="white-space: nowrap;">
              <label> אירוע:</label>
              <h3><b> <u>{{ tishInvited.tishEventTitle }}</u></b></h3>
            </div>
          </div> -->

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <contacts-select-pick [withLabel]="true" (contactSelect)="selectionChanged($event)"
              (contactChange)="selectionChanged($event)" (contactAdd)="selectionChanged($event)" [emitFullObject]="true"
              [contactID]="tishInvited.contactID" [placeHolder]="'איש...'" [withSubTitle]="true"
              [contactIdControl]="tishInvitedForm.controls.contactID"
              [contactNameControl]="tishInvitedForm.controls.tishInvitedName" [fromRecordType]="'TishInvited'"
              [formGroupModel]="tishInvitedForm">
            </contacts-select-pick>


            <!-- <auto-complete apply-enter [controllerName]="'Contacts'" [formGroupModel]="tishInvitedForm"
            [subTitle]="false"  [emitFullObject]="true" (onSelectObj)="selectionChanged($event)"
              [placeholder]="'שם מוזמן'" [idControl]="tishInvitedForm.controls.contactID"
              [nameControl]="tishInvitedForm.controls.tishInvitedName"></auto-complete>   -->


            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>מארח:</label>
              <input style="font-weight: bold;" type="text" class="form-control" [disabled]="true"
                [value]="tishInvitedForm.controls.hostContactName.value">
              <!-- <a style="color:#304cf1" *ngIf="tishInvitedForm.controls.hostInvitedID.value > 0"
                [routerLink]="[ '', {outlets: {popup: [ 'tishInviteds', tishInvitedForm.controls.hostInvitedID.value]}}]">
                  <i matTooltip="עבור לזימון המארח" class="fa fa fa-link"></i>
              </a> -->
            </div>
            <!-- <auto-complete apply-enter [placeholder]="'מארח...'"
              [controllerName]="'ViwTishInviteds/' + tishInvitedForm.controls.tishEventID?.value"
              [formGroupModel]="tishInvitedForm" [idControl]="tishInvitedForm.controls.hostInvitedID"
              [nameControl]="tishInvitedForm.controls.hostContactName">
            </auto-complete> -->

            &nbsp;&nbsp;
            <auto-complete apply-enter [controllerName]="'TishGroups/' + tishEventID" [placeholder]="'קבוצה'"
              [formGroupModel]="tishInvitedForm" [idControl]="tishInvitedForm.controls.tishGroupID"
              [nameControl]="tishInvitedForm.controls.tishGroupName"></auto-complete>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">

              <label>שם לכרטיס:</label>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                <input class="form-control narrow" placeholder="תיאור ותואר" style="text-align: right;" apply-enter
                  formControlName="specialInvitedPerfix" />

                &nbsp;&nbsp;
                <input class="form-control" placeholder="שם פרטי" style="text-align: right;" apply-enter
                  formControlName="specialInvitedFirstName" />

                &nbsp;&nbsp;
                <input class="form-control" placeholder="שם משפחה" style="text-align: right;" apply-enter
                  formControlName="specialInvitedLastName" />

                &nbsp;&nbsp;
                <input class="form-control narrow" placeholder="סיומת" style="text-align: right;" apply-enter
                  formControlName="specialInvitedSuffix" />
              </div>
              <h3 fxLayout="row" class="label">יש להכניס נתונים מסודרים בשדות המתאימים</h3>
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>מכתב:</label>
              <mat-select apply-enter class="form-control" placeholder="נשלח מכתב?" formControlName="doSendletter">
                <mat-option [value]="false">לא נשלח מכתב</mat-option>
                <mat-option [value]="true">נשלח מכתב</mat-option>
              </mat-select>
            </div>
            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>אישור השתתפות:</label>
              <mat-select apply-enter class="form-control" placeholder="משתתפף?" formControlName="invitedConfirmed">
                <mat-option [value]="false">לא משתתף</mat-option>
                <mat-option [value]="true">משתתף</mat-option>
              </mat-select>
            </div>
            &nbsp;&nbsp;
            <div class="input-group mb-3">

              <label for="isPlace">מוזמן לשיבוץ?:</label>
              <mat-select apply-enter class="form-control" placeholder="מוזמן לשיבוץ?" formControlName="isPlace">
                <mat-option [value]="false">לא לשיבוץ</mat-option>
                <mat-option [value]="true">לשיבוץ</mat-option>
              </mat-select>

            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3" *ngIf="tishInvited.invitedStatus != 'ילד'">

              <label for="quantityAdditionalTickets">כרטיסי כניסה
                נוספים:</label>
              <input type="text" class="form-control " formControlName="quantityAdditionalTickets" />
              <h3 class="label">מס' כרטיסים נוספים לכניסה בלבד</h3>

            </div>

            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label for="isPlace">האם ילד?:</label>
              <mat-select apply-enter class="form-control" placeholder="האם ילד?" formControlName="isChild">
                <mat-option [value]="false">אחר</mat-option>
                <mat-option [value]="true">ילד</mat-option>
              </mat-select>
            </div>
            &nbsp;&nbsp;
            <auto-complete-input [leftToRight]="false" apply-enter [placeholder]="'סטטוס'"
              [controllerName]="'InvitedStatuses'" [defaultLastNameToUpdateChilds]="defaultLastNameToUpdateChilds"
              [stringValueControl]="tishInvitedForm.controls.invitedStatus" [formGroupModel]="tishInvitedForm">
            </auto-complete-input>

            <!-- <input class="form-control narrow" placeholder="סטטוס" style="text-align: right;" apply-enter
            formControlName="invitedStatus" />
          -->
          </div>
          <hr>
          <textarea rows="2" class="comment" placeholder="הערה..." formControlName="comment"></textarea>


          <div class="form-row" fxLayout="row" *ngIf="tishInvited.invitedStatus != 'ילד'">
            <tishInviteds-subs [tishInvited]="tishInvited" [tishInvitedForm]="tishInvitedForm"
              [tishInviteds]="tishInvited.tishInviteds" fxLayout="row">
            </tishInviteds-subs>

          </div>

        </fieldset>
      </div>
    </div>
  </div>
  <footer-edit-buttons [dataProvider]="tishInvitedsData" [displaySaveButton]="false" [displayDeleteButton]="false"
    [router]="router" [id]="id" [disabledDeleteButton]="id <= 0" [modelFormGroup]="tishInvitedForm">
    <ng-template #customHtmlButtons>
      <button type="button" apply-enter [disabled]="!tishInvitedForm.valid" (click)="save(tishInvitedForm)"
        class="btn btn-primary navbar-btn">
        <i class="fa fa-save"></i> שמור
      </button>

      <button type="button" [disabled]="id <= 0" class="btn btn-danger navbar-btn" (click)="delete()">
        <i class="fa fa-ban"></i> מחק
      </button>

    </ng-template>
  </footer-edit-buttons>
</form>
