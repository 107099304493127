import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TishInvitedsData } from './tishInviteds.data';
import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';
import { IndexService } from '../services/index-service';
import { SubscriptionLike } from 'rxjs';
//import * as signalR from '@aspnet/signalr';

@Component({
  selector: 'tishInviteds-list',
  templateUrl: 'tishInviteds-list.tmpl.html',
  styleUrls: ['./tishInviteds.scss']
})

export class TishInvitedsList implements OnInit, AfterViewInit {
  constructor(
    public tishInvitedsData: TishInvitedsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/tishInviteds') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  groupingColumn = '';
  groupingOrderColumn = '';
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;
  public referrerCmpUrl = "";
  displayedColumns: string[] = ['buttons', 'tishInvitedID', 'tishInvitedName', 'hostContactName', 'invitedStatus', 'tishGroupName', 'tableName', 'placeNumber', 'ticketName', 'comment'];
  data: any[] = [];
  panelOpenState = false;
  resultsLength = 0;
  isRateLimitReached = false;
  defaultvalues;
  searchTishInvitedsListForm: FormGroup;
  indexService: IndexService;

  actualMinusHeight = '310';

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  @Input('tishEventID') public tishEventID?: number;
  @Input('contactID') public contactID?: number;
  @Input('hostContactID') public hostContactID?: number;
 // @Input('hostInvitedID') public hostInvitedID?: number;
  @Input('groupID') public groupID?: number;
  @Input('areaID') public areaID?: number;
  @Input('notEmbedded') public notEmbedded?: boolean;

  orderButtonIsChicked: boolean = false;
  stopLoadingPoints: boolean = false;
  isLoadingResults = false;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;
  pageEvent: PageEvent;
  pageSizeOptions;
  addNew() {
    this.router.navigate(['', { outlets: { popup: ['tishInviteds', 'add'] } }]);
  }
  onlySortRecentlyOpenedByCurUser = false;


  openSelectedRow(tishInvitedID) {
    this.router.navigate(['', { outlets: { popup: ['tishInviteds', tishInvitedID] } }]);
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.tishInvitedID === row.tishInvitedID);
    if (found) {
      return true;
    }
    return false;
  }

  // public getTishInviteds() {
  // 	if (this.paginator) {
  // 		this.searchTishInvitedsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
  // 	}
  // 	if (this.tishEventID && this.tishEventID > 0) {
  // 		this.searchTishInvitedsListForm.controls['tishEventID'].setValue(this.tishEventID);
  // 	}

  // 	this.isLoadingResults = true;
  // 	sessionStorage.setItem(this.router.url, JSON.stringify(this.searchTishInvitedsListForm.value));
  // 	return this.tishInvitedsData!.getList(this.searchTishInvitedsListForm.value)
  // 		.subscribe((data: any) => {
  // 			this.data = data;
  // 			this.sourceList = data.list;

  // 			this.page = data.list.length;
  // 			this.pageRows = data.list.length;
  // 			this.rowsCount = data.rowsCount;

  // 			this.isLoadingResults = false;
  // 			this.isRateLimitReached = false;

  // 			//trick
  // 			this.stopLoadingPoints = false;


  // 			this.selection.isSelected = this.isChecked.bind(this);
  // 		});
  // }

  initForm() {
    this.searchTishInvitedsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      tishEventID: [],
      areaID: [],
      groupID: [],
      tishGroupName: [],
      tishAreaName: [],
      contactID: [],
      contactName: [],
      hostContactName: [],
      hostContactID: [],
      notEmbedded: [null],
      tishInvitedID: [],
      tishInvitedName: [],
      isPlaces: [(this.contactID > 0 ? null : true)],
      doSendletters: [],
      invitedConfirmeds: [],
      invitedStatus: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchTishInvitedsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.tishInvitedID));
      this.selectedRowsChange.emit(x.source.selected);
    });
    let frm = this.searchTishInvitedsListForm;


    this.defaultvalues = Object.keys(this.searchTishInvitedsListForm.value)
      .filter(key => frm.controls[key].validator == Validators.required)
      .reduce((obj, key) => {
        obj[key] = frm.controls[key].value;
        return obj;
      }, {});

  }

  ngOnInit() {
    this.initForm();


    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchTishInvitedsListForm.patchValue(frm);
    }

    this.searchTishInvitedsListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
      { key: "contactID", value: this.contactID },
      { key: "hostContactID", value: this.hostContactID },
      //{ key: "hostInvitedID", value: this.hostInvitedID },
      { key: "tishAreaID", value: this.areaID },
      { key: "tishEventID", value: this.tishEventID },
      { key: "tishGroupID", value: this.groupID },
    ];

    this.indexService = new IndexService(this.searchTishInvitedsListForm, params, this.tishInvitedsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();
    this.loadEventGroups();
    this.loadTishInvitedStatuses();

    // if (this.commitmentID) {
    //   this.actualMinusHeight = '460';
    // }
    // else if (this.contactID) {
    //   this.actualMinusHeight = '362';
    // }

    // this.stopLoadingPoints = true;
    // this.initForm();
    // let item = sessionStorage.getItem(this.router.url);
    // if (item) {
    // 	let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
    // 	this.searchTishInvitedsListForm.patchValue(frm);
    // }
    // this.getTishInviteds();
  }

  tishInvitedStatuses: [];
  loadTishInvitedStatuses() {
    this.servicesData.getTishInvitedStatuses(this.tishEventID).subscribe((x: any) => {
      this.tishInvitedStatuses = x;
    });
  }
  tishGroups: [];
  loadEventGroups() {
    this.servicesData.getTishGroups(this.tishEventID).subscribe((x: any) => {
      this.tishGroups = x;
    });
  }
  ngAfterViewInit(): void {
    if (this.contactID) {
      this.displayedColumns.push('tishEventTitle');

    }
  }

}
