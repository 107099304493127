import { TishPlacesData } from './../tishPlaces.data';
import { TishInvitedsData } from './../../tishInviteds/tishInviteds.data';
import { ModalService } from './../../components/modal/modal.svc';
import { TishTable } from './../../tishTables/tishTable/tishTable';
import { TishInvited } from './../../tishInviteds/tishInvited/tishInvited';
import { MatDialog } from '@angular/material/dialog';
import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChildren, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TishPlace } from './tishPlace';
import { TishPlaceDlg } from './tishPlace-edit-dlg.cmp';
import { TishPlaceMoveDlg } from './tishPlace-move-dlg.cmp';
import { InputInfoComponent } from 'src/app/components/input-info/input-info.component';
import { TishInvitedMap } from 'src/app/tishInviteds/tishInvited/tishInvited-map.cmp';
import { I } from '@angular/cdk/keycodes';

declare var jQuery: any;



@Component({

  selector: 'tishPlace-map',
  templateUrl: './tishPlace-map.tmpl.html',
  styleUrls: ['./../tishPlaces.scss']
})

export class TishPlaceMap implements OnInit, AfterViewInit {
  constructor(
    private tishPlacesData: TishPlacesData,
    private tishInvitedsData: TishInvitedsData,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private modal: ModalService,
    public dialog: MatDialog,
    private cdref: ChangeDetectorRef,
  ) {
  }


  @ViewChildren('placeForinvited') placeForinviteds: QueryList<ElementRef>;

  @Input('tishPlaceForm') public tishPlaceForm: FormGroup;
  @Input('searchTishPlaceNumber') public searchTishPlaceNumber: number = null;
  @Input('tishEventID') public tishEventID: number;
  @Input('narrowTable') public narrowTable: boolean = false;
  @Input('isMarginActive') public isMarginActive:  boolean = false;


  @Output() dragInvited = new EventEmitter<boolean>();
  @Output() dropedElement = new EventEmitter<boolean>();

  @ViewChild(TishInvitedMap, { static: false }) public invitedMap: TishInvitedMap;

  public id: number;
  tishTable: TishTable;
  tishPlace: TishPlace;
  // onPlaceNumberRightClick() {
  //   let tp = this.tishPlace;

  //   this.modal.show({
  //     Header: 'הוזזת מקומות',
  //     BodyComponentType: TishPlaceMoveDlg,
  //     Actions: [
  //       { Text: 'ביטול', CssClass: 'btn-default', Action: () => true },
  //       { Text: 'אישור', CssClass: 'btn-primary', Action: this.movePlaces, Validate: true }
  //     ],
  //     Data: tp
  //   });

  // }

  public onPlaceNumberRightClick(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(TishPlaceMoveDlg, {
        data: this.tishPlace
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.movePlaces(result);
        }
      });
    }, 200);
  }

  movePlaces = (formData: FormGroup) => {
    let tishTableID = formData.controls['tishTableID'].value;
    let fromPlace = formData.controls['fromPlace'].value;
    let tillPlace = formData.controls['tillPlace'].value;
    let places = formData.controls['places'].value;

    this.tishPlacesData.movePlaces(tishTableID, (fromPlace % 2) == 0, fromPlace, tillPlace, places)
      .subscribe((c: any) => {
        return true;
      });
  }

  editPlace() {
    let tt = this.tishPlace;
    tt.tishEventID = this.tishEventID; //TODO TRICK
    setTimeout(() => {
      const dialogRef = this.dialog.open(TishPlaceDlg, {
        data: tt
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.saveTishPlace(result);
        }
      });
    }, 200);
  }



  saveTishPlace = (formData: FormGroup): void => {
    if (formData.valid) {
      this.tishPlace = formData.value;
      this.tishPlaceForm = formData;
      this.save();
    }
  }


  public save() {
    if (this.tishPlaceForm.valid) {
      this.tishPlacesData.update(this.tishPlaceForm.value)
        .subscribe((c: any) => {
          this.id = c.value;
          if (this.id) {
            this.load();
          }
        });
    } else {
      console.log('not valid');
      console.log(this.tishPlaceForm.errors);
    }
  }

  rerender = false;
  load() {
    this.tishPlacesData.getMap(this.id)
      .subscribe((place: any) => {
        if (place != null) {
          this.tishPlace = place;
          if (this.tishPlace.tishInvited == null) {
            this.tishPlace.tishInvited = new TishInvited();
            this.tishPlace.tishInvited.tishEventID = this.tishEventID ?? this.tishTable?.tishEventID;
          }
          else {
            //הוספת Form מוזמן למקום
          }

          //מקום
          if (this.tishPlaceForm) {
            this.tishPlaceForm.patchValue(this.tishPlace);
            this.tishPlaceForm.controls["tishInvited"] = this.tishInvitedsData.initForm();
            this.tishPlaceForm.controls["tishInvited"].patchValue(this.tishPlace.tishInvited);

           // console.log('tishPlaceForm', this.invitedMap);
            //מוזמן
            if(this.invitedMap){
              this.invitedMap.tishInvited = this.tishPlace.tishInvited;
              this.invitedMap.tishPlaceForm = this.tishPlaceForm;
            }

            //  this.invitedMap.tishInvitedForm.updateValueAndValidity();
            this.rerender = true;
            this.cdref.detectChanges();
            this.rerender = false;
          }


        }
      });
  }




  ngOnInit(): void {
    this.tishPlace = this.tishPlaceForm.value;
  }
  onDragInvited(e: boolean) {
    this.dragInvited.emit(e);
  }
  onDropedElementFn(e: boolean) {
    this.dropedElement.emit(e);
  }

  ngAfterViewInit(): void {
    var tishPlacesData = this.tishPlacesData;
    var tishPlaceForm = this.tishPlaceForm;

    let onDropdElmnt = this.dropedElement;
    this.placeForinviteds.forEach(element => {
      let placeForinvited = jQuery(element.nativeElement);
      if (this.tishPlaceForm.controls.isActive.value == 1) {
        placeForinvited.droppable({
          hoverClass: 'validClass',
          // activeClass: "ui-state-highlight",
          //  accept: ".draggableInvited",
          // classes: {
          //     // "ui-droppable-active": "ui-state-active",
          //     "ui-droppable-hover": "ui-state-hover"
          // },
          // activeClass: "ui-state-highlight",
          snap: true,
          drop: function (e, ui) {
            onDropdElmnt.emit(true);
            var data = ui.draggable.data('srcObj');
            if (data) {
              let srcTishInvited;
              if (data.tishInvited) {
                let tishInvitedJsn: string = data.tishInvited;
                srcTishInvited = <TishInvited>jQuery.parseJSON(tishInvitedJsn);

                //בדיקה והודעה בהתאם אם יש כבר מישהוא על המקום
                if (data.tishPlaceForm) {
                  data.tishPlaceForm.controls.tishInvitedID.setValue(null);
                }
                let cnfrm = true;
                if (tishPlaceForm.controls.tishInvitedID.value && tishPlaceForm.controls.tishInvitedID.value != null) {
                  if (!confirm(`${tishPlaceForm.value.tishInvitedName}\nכבר משובץ במקום זה, והוא יעבור לרשימת ההמתינים,\nהאם להמשיך?`)) {
                    cnfrm = false;
                  }
                }

                if (cnfrm == true) {
                  //    tishPlaceForm.controls.tishInvited.patchValue(srcTishInvited)
                  tishPlaceForm.controls.tishInvitedID.patchValue(srcTishInvited.tishInvitedID)
                  if (tishPlaceForm && tishPlaceForm.valid) {
                    tishPlacesData.update(tishPlaceForm.value)
                      .subscribe((c: any) => {
                        console.log(c);
                      });
                    ui.helper.css("left", "");
                    ui.helper.css("top", "");
                    ui.helper.css("position", "relative");
                    $(this).html(ui.draggable);
                  } else {
                    console.log('not valid errors', tishPlaceForm.errors);
                  }
                }
              }
            }

            // e.preventDefault();
            // ui.helper.css("left", "");
            // ui.helper.css("top", "");
            // ui.helper.css("position", "relative");
            // $(this).html(ui.draggable);


            //למירכוז האלמנט
            // let ofst: any = $(this).offset();
            // $(ui.draggable).offset(ofst);
            // $(ui.draggable).css("position", "unset");
          }
        }
        );
      }
    });


  }
}
