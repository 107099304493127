//import { NgxDeviceInfoService } from 'ngx-device-info';


import { NavbarTopComponent } from './components/navbar-top/navbar-top.cmp';
import { ToraxHttp } from './utils/custom-http.svc';
import { ViewChild, AfterViewInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from './services';
import { User } from './_models';
import { smoothlyMenu } from './app.helpers';
import { ServicesData } from './services/services.data';
import { Subscription } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavItem } from './components/menu-list-item/nav-item';
import { GlobalHelper } from './_helpers/global-helper';
//import { AngularDeviceInformationService } from 'angular-device-information';

declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @Inject(DOCUMENT) private _document: Document



  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  userName = '';
  userRoles: string[];
  depositsAlerts = false;

  navItems: NavItem[] = [
    { route: 'dashboard', displayName: 'פעילויות ובקרה', iconName: 'fa-th-large', roles: 'dashboard' },  //, exactActive: true
    { route: 'contacts', displayName: 'רשימות אנ"ש', iconName: 'fa-address-book-o', roles: 'contacts' },
    {
      displayName: 'רשימות', iconName: 'fa-bars', children: [
        { route: 'commitments', displayName: 'הסכמים/התחייבויות', roles: 'commitments' },
        { route: 'paymentsPlans', displayName: 'תוכניות תשלום', roles: 'paymentsPlans' },
        { route: 'payments', displayName: 'תשלומים', roles: 'payments' },
        { route: 'payMethods', displayName: 'אמצעי תשלום', roles: 'payMethods' },
        { route: 'completeHoldings', displayName: 'החזקת בית המדרש', roles: 'Holdings' },
        { route: 'transferTasks', displayName: 'ייבוא העברות', roles: 'TransferTasks' },
        { route: 'shtibels', displayName: 'שטיבלך', roles: 'shtibels' },
      ]
    },
    { route: 'ivrAssignmentUserTasks', displayName: 'משימות תחזוקה', iconName: 'fa-wrench', roles: 'IvrAssignmentUserTasks' },
     // {
    //     displayName: 'התחייבויות', iconName: 'fa-money', children: [
    //         { route: '/reports/commitments/new', displayName: 'חדשות' },
    //         { route: '/reports/commitments/uncompleted', displayName: 'לטיפול' }
    //     ]
    // },
    {
      displayName: 'כיבודים ועליות', iconName: 'fa fa-gift', children: [
        { route: 'honorsSets', displayName: 'שבתות-אירועים', roles: 'honorsSets' },
        { route: 'honors', displayName: 'רשימת כיבודים', roles: 'honors' },
        { route: 'honorTypes', displayName: 'הגדרת סוגי כיבודים', roles: 'honorTypes' },
      ]
    },
    {
      displayName: 'סטנציה', iconName: 'fa fa-university', children: [
        { route: 'registrations', displayName: 'רשימות', roles: 'registrations' },
        { route: 'registrations/groupRegistrations', displayName: 'רשימות מקובץ לאנשים', roles: 'groupRegistrations' },
        { route: 'registrationEvents', displayName: 'אירועים', roles: 'registrationEvents' }
      ]
    },
    { route: 'tishEvents', displayName: 'שיבוץ מקומות', iconName: 'fa fa-sitemap', roles: 'tishEvents' },
    { route: 'deposits-main', displayName: 'ספר קופה - ישראל', iconName: 'fa fa-book', roles: 'deposits', notificationAlert: this.depositsAlerts },

    {
      displayName: 'ניהול', iconName: 'fa-cog', children: [
        { route: 'reportscenter', displayName: 'מרכז דוחו"ת', roles: 'ReportsCenter' },
        { route: 'emergencySquads', displayName: 'כיתת כוננות', roles: 'emergencySquads' },
        { route: 'campaigns', displayName: 'קמפיינים', roles: 'Campaign' },
        { route: 'lotteries', displayName: 'כרטיסי הגרלה', roles: 'Lottery' },
        { route: 'users', displayName: 'משתמשים', roles: 'SystemAdministrator' },
        { route: 'groups', displayName: 'הגדרת קבוצות', roles: 'groups' },
        { route: 'processTypes', displayName: 'סוגי תהליכים', roles: 'processTypes' },
        { route: 'letterSettings', displayName: 'תבניות מכתבים', roles: 'letterSettings' },
        { route: 'commitmentReasons', displayName: 'סיבות התחייבות', roles: 'commitmentReasons' },
        { route: 'vouchersOrganizations', displayName: 'ארגוני שוברים', roles: 'vouchersOrganizations' },
        { route: 'collectionBuilders', displayName: 'יחידות סליקה', roles: 'collectionBuilders' },
        { route: 'transactions', displayName: 'טרנזקציות-אשראי', roles: 'transactions' },
        { route: 'cities', displayName: 'רשימת ערים', roles: 'cities' },
        { route: 'terms', displayName: 'מסופים', roles: 'terms' },
        { route: 'countries', displayName: 'שיוך מסופים למדינות', roles: 'countries' },
        // { route: 'collectionControl', displayName: 'בקרת גביה', roles: 'collectionControl' },
        // { route: 'states', displayName: 'מחוזות' },
      ]
    },
     { route: 'yarzeits', displayName: 'מערכת יארצייטים', iconName: 'fa-fire', roles: 'Yarzeits' },
    { route: 'calendar', displayName: 'לוח שנה', iconName: 'fa fa-calendar', roles: 'SystemAdministrator' },
  ];

  title = 'G-X';
  selected: any;
  subSelected: any;
  currentUserSubscription: Subscription;
  imgsrc;

  //public getScreenWidth: any;
   constructor(
    public router: Router,
    public titleService: Title,
    private authenticationService: AuthenticationService,
    public location: Location,
    public servicesData: ServicesData,
    private toraxHttp: ToraxHttp,
    private cdRef: ChangeDetectorRef,
    private observer: BreakpointObserver,
    public gh: GlobalHelper,
    //public device: NgxDeviceInfoService
  //  private deviceInformationService: AngularDeviceInformationService
  ) {
    let dUrl = document.URL;
    if (dUrl.indexOf('10.0.0.75') > -1 || dUrl.indexOf('shmuel-site') > - 1 || dUrl.indexOf('localhost') > - 1 || dUrl.indexOf('127.0.0.1') > - 1) {
      this.testModeStyle = "solid 50px red";
    } else {
      this.testModeStyle = "none";
    }
    if (dUrl.indexOf('screenIndex') > -1) {
      this.screenMode = 'screenIndex';
    } else {
      this.screenMode = '';
    }
    // this.device.getDeviceInfo().then((data) => {
    //   this.deviceInfo = data;
    // });
  }
 // deviceInfo:any;
  screenMode: string = '';
  testModeStyle: string = "none";
  timer;
  @ViewChild("navBarTop") navBarTop: NavbarTopComponent;
  isMobile: boolean = false;
  topNavMargin = '200px';
  ngAfterViewInit() {

    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      this.topNavMargin = '0px';
      if (res.matches && this.sidenav) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else if (this.sidenav) {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });


    this.checkNotifications();
    this.timer = setInterval(() => {
      this.checkNotifications();
    }, 99000000);
    this.cdRef.detectChanges();
  }


  checkNotifications() {
    let aa = this.servicesData.getNotificationsAlerts();
    let a = this.navItems.filter(y => y.route == 'deposits-main');

    a[0].notificationAlert = true; // aa != null ? true: false;

  }

  get currentUser(): User {
    if (this.authenticationService) {
      return this.authenticationService.currentUserValue;
    }
  }

  public screenHeight: any;
  public screenVHHeight: any;
  public screenVHHeightWIn

  ngOnInit(): void {
    // console.log("isMobile", this.deviceInformationService.isMobile());  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log("isTablet",this.deviceInformationService.isTablet());  // returns if the device is a tablet (tablet iPad etc)
    // console.log("isDesktop",this.deviceInformationService.isDesktop()); // returns if the app is running on a Desktop browser.
    // console.log("getDeviceType",this.deviceInformationService.getDeviceType()); // returns if the app is running on a Desktop browser.
    // console.log("os",this.deviceInformationService.getDeviceInfo().os);  // returns os name like Windows/Andtoid/iOS/Linux/Mac OS X etc
    // console.log("osVersion",this.deviceInformationService.getDeviceInfo().osVersion);  // returns os version like 10/8.1/7 ...etc
    // console.log("browser",this.deviceInformationService.getDeviceInfo().browser);  // returns browser name like chrome/firefox ...etc
    // console.log("browserVersion",this.deviceInformationService.getDeviceInfo().browserVersion);  // returns browser version as number
    // console.log("browserMajorVersion",this.deviceInformationService.getDeviceInfo().browserMajorVersion);  // returns full browser version as number
    // console.log("screen_resolution",this.deviceInformationService.getDeviceInfo());  // returns screnn size like 1390x860/640x800 ...etc
    // console.log("cookies",this.deviceInformationService.getDeviceInfo().cookies);  // returns cookies enabled or no
    // console.log("userAgent",this.deviceInformationService.getDeviceInfo().userAgent);  // returns userAgent

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      // console.log('vh', vh);
      // console.log(' document.documentElement.style',  document.documentElement.style);
      // console.log(' window.innerHeight',  window.innerHeight);
      // console.log('  document.documentElement.style',   document.documentElement.style);
    });
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Android") > -1) {
      this.isMobile = true;
    }
    this.gh.setScreenVHHeight();

    this.authenticationService.currentUser.subscribe(x => {
      if (x) {
        this.userName = `${x.firstName} ${x.lastName}`;
        this.userRoles = x.roles;
      }
    });


    this.router.events.subscribe((route) => {
      if (route instanceof RoutesRecognized) {
        let titleResult = '';

        let r: ActivatedRouteSnapshot | null = route.state.root;
        while (r) {
          if (r.data && r.data.title) {
            titleResult = r.data.title;
          }
          r = r.firstChild;
        }
        this.title = titleResult;

        this.titleService.setTitle(`G | ${titleResult || 'X'}`);
      }
    });

    this.authenticationService.currentUser.subscribe(u => {
      if (u) {
        this.isLoggedIn().subscribe(x => {
          if (!x && this.location.path().indexOf("/login") == -1) {
            this.authenticationService.logoutMe();
          } else if (x) {
          }
        }
        )
      }
    });


  }

  select(item) {
    this.selected = (this.selected === item ? null : item);
  }
  subSelect(item) {
    this.subSelected = (this.subSelected === item ? null : item);
  }
  isActive(item) {
    return this.selected === item;
  }


  itemCollapsed(e) {
  //  console.log(e);
  }


  @HostListener("keydown.enter", ['$event'])
  enterClick() {
    let curElement = (<any>event).target;

    let hasOtherElement = false;
    if (!curElement.attributes.hasOwnProperty('enter-tab')) {
      curElement.setAttribute('enter-tab', 0);
      hasOtherElement = true;  //למקרה שנמצא במשבצת מחוץ לסדר, ראה בהמשך ניתוק מהרשימה בחזרה
    }

    let closestForm = curElement.closest('form');
    if (closestForm) {
      let elements = closestForm.querySelectorAll('[enter-tab]:not(:disabled)');
      if (hasOtherElement) {
        curElement.removeAttribute('enter-tab');
      }

      if (elements && elements.length <= 1) {
        //  event.preventDefault();
        return;
      } else {
        let elementsArr = Array.from(elements);
        let index = elementsArr.indexOf(curElement);
        if ((elementsArr.length - 1) == index) {
          //  event.preventDefault();
          return;
        } else {
          let nextElement: any = elementsArr[index + 1];
          let jqNextElement = jQuery(nextElement);
          let isFocusable = jqNextElement.is(':focusable')
          if (!isFocusable) {
            nextElement = nextElement.querySelector('input:not(:disabled)');
          }
          if (nextElement) {
            nextElement.focus();
            event.preventDefault();
          } else {
            return;
          }

        }
      }
    }
  }


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.gh.setScreenVHHeight();
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.authenticationService.resetTimer();
  }

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keypress', ['$event'])
  setSearchFocus(event) {
    if (event.ctrlKey && event.keyCode == 70) {
      //Block CTRL + F event
      if (this.navBarTop.searcAc.txtSearch)
        (<any>this.navBarTop.searcAc.txtSearch).nativeElement.focus();

      event.preventDefault();
    }
  }

  toggleNavigation(): void {
    jQuery("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  //כרגע לא חייב לעבור דרך ה-APP רק כדי שבהמשך נוכל להוסיף תמונת צילום התקלה
  sendSupportEmail() {
    let address = 'r0527145566@gmail.com';
    var subject = `בקשת תמיכה - מאת ${this.currentUser.firstName} ${this.currentUser.lastName}`;
    let mail = document.createElement("a");
    mail.target = "_blank";
    let body = "פרט כאן את תוכן התקלה וצרף צילום מסך...%0D%0A%0D%0Aבברכה%0A" + this.currentUser.firstName + "%0D%0A%0D%0A========================%0Aמידע טכני (לא למחוק)%0A" + this.location.path() + "%0D%0A%0D%0A";
    var win = window.document.open('', '_blank', 'emailWindow');
    win.location.href = 'mailto:' + address + '?subject=' + subject + '&body=' + body;
  }

  reLoadAllLists() {
    this.servicesData.loadAllLists();
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  backClicked() {
    this.location.back();
  }

  isLoggedIn() {
    return this.toraxHttp.get(`/isLoggedIn`);
  }

  logoutBtn() {
    let isconfirm = true;
    let loginUrl = "login"
    // let returnUrl = "";
    let returnUrl = "dashboard";
    // const userAgent = window.navigator.userAgent;
    // if (userAgent.indexOf("Android") > -1 && event && event.target) {
    //   //let src = (<any>event.target).id || (<any>event.target).parentElement.id;

    //   isconfirm = confirm("לאפס שם משתמש וסיסמה?");
    //   if (isconfirm) {
    //     loginUrl = "login-mobile";
    //     this.authenticationService.logoutMe(loginUrl, returnUrl);
    //   }
    // }
    // else {
    this.authenticationService.logoutMe(loginUrl, returnUrl);
    //}
  }

}
