<div class="column" *ngIf="tishEvent">
  <div class="row">
    <panel-box [showTitle]="false">
      <div class="row">
        <div class="col-sm-2">
          <h2>
            {{ tishEvent.tishEventTitle }}
          </h2>
          <div [formGroup]="searchForm" style="white-space: nowrap; display: inline-flex;">
            <auto-complete #searcAc [withSubTitle]="true" [smallTemplate]="true" (onSelectObj)="searchSelect($event)"
              [placeholder]="'חיפוש...'" [controllerName]="'ViwTishInviteds/' + id" [formGroupModel]="searchForm"
              [idControl]="searchForm.controls.tishInvitedID" [emitFullObject]="true"
              [nameControl]="searchForm.controls.query">
            </auto-complete>
            <i class="fa  fa-eraser" (click)="clearSerch()" style="margin: 0 auto;"></i>
          </div>
        </div>
        <div fxLayout="column" fxFlex  class="well">

          <div fxLayout="row" style="margin-bottom: 0px;" *ngIf="eventStatus">
            <div fxLayout="column" fxFlex>

              <div fxLayout="row" class="label-content-div" fxFlex>
                <span class="val-lebel">מוזמנים כולל:</span>
                <span class="val-content">{{ eventStatus.totalInviteds }}</span>
              </div>
              <div [ngStyle]="{'color': (eventStatus.waitingInvited && eventStatus.waitingInvited.length > 0 ? 'red': '')}" fxLayout="row" class="label-content-div" fxFlex>
                <span class="val-lebel">ממתינים לשיבוץ:</span>
                <span class="val-content">{{ eventStatus.waitingInvited }}</span>
              </div>

            </div>
            <div fxLayout="column" fxFlex>

              <div fxLayout="row" class="label-content-div" fxFlex>
                <span class="val-lebel">מוזמנים ששובצו:</span>
                <span class="val-content">{{ eventStatus.embeddedInvited }}</span>
              </div>
              <div fxLayout="row" class="label-content-div">
                <span class="val-lebel">שיבוצים כפולים: <i  *ngIf="eventStatus.withDuplicatePlaces > 0" class="fa fa-warning" style="color:#f0b111"></i></span>
                <span (click)="loadDuplicates()"  class="val-content" fxFlex>{{ eventStatus.withDuplicatePlaces }}</span>
                   <mat-card *ngIf="viewDuplicates && viewDuplicates == true" style="white-space: nowrap; position:fixed; top:5px; height: 170px; overflow-y: auto; border: solid; border-color: #f0b111;">
                    <mat-card-content style="padding: 0 !important" >
                      <mat-card-header>
                       <mat-card-title>שיבוצים כפולים</mat-card-title>
                        <button (click)="viewDuplicates = false">X</button>
                      </mat-card-header>

                        <table>
                          <tr *ngFor="let dpl of duplicates">
                            <td style="padding:2px">{{dpl.firstName}} {{dpl.lastName}}</td>
                            <td>{{dpl.shortAddressTitle}}</td>
                            <td> שולחן: {{dpl.tableName}}, מקום: {{dpl.placeNumber}}</td>
                          </tr>
                        </table>

                      </mat-card-content>
                  </mat-card>
              </div>

            </div>
            <div fxLayout="column" fxFlex>
              <div fxLayout="row" class="label-content-div" fxFlex>
                <span class="val-lebel">מקומות כולל:</span>
                <span class="val-content">{{ eventStatus.countPlaces }}</span>
              </div>

              <div fxLayout="row" class="label-content-div" fxFlex [ngStyle]="{'color': eventStatus.availablePlaces > 0 ? 'green' : 'red'}" style="font-weight: bold;">
                <span class="val-lebel">מקומות פנויים:</span>
                <span class="val-content">{{ eventStatus.availablePlaces }}</span>
              </div>

            </div>
            <div fxLayout="column" fxFlex>
              <button class="btn btn-circle" style="float:left;" (click)="loadEventStatus()" title="רענן"><i
                class="fa fa-refresh"></i></button>
            </div>
          </div>
        </div>
        <div class=" col-sm-6 text-left">
          <a class="btn btn-success" [routerLink]="['', {outlets: {popup: ['tishEvents', tishEvent.tishEventID, 'tishInviteds', 'add' ]}}]">הוסף מוזמן</a>
        <!-- <a class="btn btn-success" (click)="addInvitedDlg()">הוסף מוזמן</a> -->
          <a class="btn btn-success" (click)="addGroupDlg()">הוסף קבוצה</a>
          <a class="btn btn-primary" (click)="addAreaDlg()">הוסף איזור</a>
          <a class="btn btn-success" (click)="initSize()">גודל מקורי</a>

          <!-- <button [ngxPrint]
          [printStyle]="{ '#canvas' :{'background-image': 'url(/' + backroundImage + ') !important', 'background-repeat': 'no-repeat  !important', 'width': '2800px', 'background-position': 'center center !important'}, '.fa-save' :{'display': 'none'}}"
            class="btn btn-success" [useExistingCss]="true" printSectionId="canvas-parent" >הדפס מפה (עם
          רקע)</button>
        <button [ngxPrint]
          [printStyle]="{ '#canvas' :{'width': '2800px', 'background-position': 'center center !important'}, '.fa-save' :{'display': 'none'}}"
            class="btn btn-success" [useExistingCss]="true" printSectionId="canvas-parent" >הדפס מפה (ללא
          רקע)</button>
           <button [ngxPrint]
          [printStyle]="{ '#canvas' :{'width': '2800px', 'background-position': 'center center !important'}, '.fa-save' :{'display': 'none'}, '.ticketLabel' :{'display': 'none'}}"
            class="btn btn-success" [useExistingCss]="true" printSectionId="canvas-parent" >הדפס מפה</button> -->
        <br>
          <a class="btn btn-info" (click)="downloadExls()">יצוא מוזמנים</a>
          <a class="btn btn-info" (click)="downloadTicketsExls()">יצוא כרטיסים</a>
          <a class="btn btn-info" (click)="downloadTishEventTicketsForLettersExls()">יצוא למיזוג מכתבים עם נתוני
            כרטיסים</a>
          <a class="btn btn-info" (click)="downloadExlsForMerge()">יצוא למיזוג מדבקות</a>
          <a class="btn btn-info" (click)="downloadExlsPlacesSticks()">מדבקות מקומות לשולחנות</a>
          <br>

        </div>
      </div>
    </panel-box>

  </div>

  <tishInviteds-list-map (loadEventStatusDelgt)="loadEventStatus($event)" (onDropedInvited)="onDropedElementFn($event)"
    (onStartInvitedDragging)="startDragging($event)" [eventStatus]="eventStatus" [tishEvent]="tishEvent"
    [tishEventID]="id">
  </tishInviteds-list-map>
  <div dir="ltr" class="demo">
    <!-- width:1550px !important; height:600px  !important;  -->
    <pinch-zoom [minScale]="0.2" #pinch [ngStyle]="{'height.px': docHeight, 'width.px':  docWidth}"
      style="margin: auto;">
      <canvas #backCanvas id="backCanvas" class="canvasSection" style="width: 6000px; height: 1700px;" ></canvas>
      <!-- (click)="viwXY($event)" -->
      <div class="hello helloContainer" #helloContainer (contextmenu)="onRightClick($event)"
        style="width:100% !important; height:100% !important">
        <div id="canvas-parent">
          <div #canvas id="canvas">
            <tishTable-map [searchTishTableName]="searchTishTableName" [searchTishPlaceNumber]="searchTishPlaceNumber"
              *ngFor="let ttId of tishEvent.tishTablesIds; let ix=index" #tablesChildren
              style="position: absolute; top: 0; left: 0" (onStartInvitedDragging)="startDragging($event)"
              (onStartTableDragging)="startDragging($event)" (onDropedElement)="onDropedElementFn($event)"
              [tishTableID]="ttId" [tishEventForm]="tishEventForm" [ix]="ix">
              Loading...
            </tishTable-map>
            <div [hidden]="hideMenubtns" class='cntnr intable' style="position: absolute;">
              <ul id='items' style="list-style: none;">
                <li>
                  <a (click)="addTableDlg()">הוסף שולחן</a>
                </li>
                <li>
                  <a (click)="moveTableDlg()">העבר שולחן לכאן</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </pinch-zoom>
  </div>
</div>
