<div *ngIf="tishInvitedForm" [formGroup]="tishInvitedForm"  [ngClass]="{'list-row-invited': rowTemplate}" class="chair-center draggableInvited invited sortable" #invited >
  <i *ngIf="tishInvited.tishInvitedID" (click)="editInvited(tishInvited.tishInvitedID)" style="transform: rotate(90deg); line-height: 5px; cursor: pointer;" class="fa fa-ellipsis-v hidden-print"></i>
    <i *ngIf="tishInvited.comment" style="font-size: 10px; display: inline; color: yellow;" class="fa fa-angle-double-down hidden-print" customToolTip [contentTemplate]="template">

    </i>
    <br>
    <ng-template #template>
     <div style="padding: 6px;"> {{tishInvited.comment}}</div>

  </ng-template>
  <label [ngStyle]="{'transform' :  transform}" *ngIf="tishInvited" class="ticketLabel"  >
    {{tishInvited.firstName}} {{tishInvited.lastName}}
    <i *ngIf="tishInvited.hostContactName" class="fa fa-heart"style="color: #3f51b59c;font-size: 60%;" [matTooltip]="('מארח: ' +tishInvited.hostContactName)"></i>
    <br *ngIf="tishInvited.cityName">
    <small>{{tishInvited.cityName}}</small>
    </label>
</div>

