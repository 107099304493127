﻿<div *ngIf="payment">
    <div class="modal-header">
        <div class="row">
            <div class="col-sm-4">
                <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', payment.contactID]}}]">
                    <h2>{{ payment.contactName }}</h2>
                </a>
                <h3>{{ payment.commitmentTitle }}</h3>
                <h3 style="color:red;"
                    *ngIf="payment.transactionView && payment.transactionView.amount == 0 && payment.transactionView.statusResponseName.includes('ממתין')">
                    תשלום במינוס עבור זיכוי תשלום אחר</h3>
                <h3 style="color:red;"
                    *ngIf="payment.transactionView && payment.transactionView.amount == 0 && payment.transactionView.statusResponseName.includes('ממתין')">
                    לצורך ביצוע ניסוי לזיכוי מחדש יש לגשת לתשלום המגביל ולבצע נסיון זיכוי מחדש</h3>
            </div>

            <div class="col-sm-8 text-left">
                <a *ngIf="payment.canMakeClearing == true && payment.isActive == true && (payment.payMethodTitle.includes('CC') || payment.payMethodTitle.includes('אשראי'))"
                    class="btn btn-info" (click)="openCcChargeDlg()">
                    <i class="fa fa-credit-card"></i>&nbsp;סליקת אשראי </a>
                <a class="btn btn-primary" *ngIf="payment.canMakeClearing == true && (payment.termID == 8 || payment.payMethod?.termID == 8)"
                [routerLink]="[ '', {outlets: {popup: [ 'collectionBuilders', 'add', (payment.termID  || payment.payMethod?.termID), payment.paymentID]}}]">
                    <i class="fa fa-folder-o"></i>&nbsp;עבור לסליקת מסב</a>
                <a *ngIf="payment.receiptID > 0" class="btn btn-primary" (click)="createAndPrintReceipt()">
                    <i class="fa fa-print"></i>&nbsp;הדפס קבלה</a>
                <a *ngIf="payment.receiptID > 0" class="btn btn-info" (click)="createAndDownloadReceipt()">
                    <i class="fa fa-download"></i>&nbsp;הורד קבלה</a>
                <div *ngIf="!payment.receiptID" class="btn-group">
                    <button data-toggle="dropdown" class="btn btn-primary dropdown-toggle">צור והדפס
                        קבלה...&nbsp;&nbsp;
                        <span class="fa fa-print"></span>
                    </button>
                    <ul class="dropdown-menu">
                        <li>
                            <a mat-menu-item (click)="createAndPrintReceipt()">תבנית ברירת
                                מחדל</a>
                        </li>
                        <li *ngFor="let ls of servicesData.ReceiptsLetterSettings">
                            <a mat-menu-item (click)="createAndPrintReceiptWithTemplate(ls.id)">תבנית
                                {{ls.title}}</a>
                        </li>
                    </ul>
                </div>
                <a *ngIf="payment.receiptID > 0" class="btn btn-danger" (click)="openCancellationReceiptDlg()">
                    <i class="fa fa-print"></i>&nbsp;ביטול קבלה</a>

                <a class="btn btn-primary" *ngIf="payment.canReturned == true" (click)="refundPayment()">
                    <i class="fa fa-print"></i>&nbsp;זיכוי חיוב אשראי</a>

            </div>
        </div>
    </div>
    <div class="modal-body" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
        <div fxLayout="row"   style="overflow: auto;" fxLayout.xs="row wrap">
            <payment-short-details [payment]="payment" fxFlexAlign="start end"></payment-short-details>

            <mat-card *ngIf="payment.isHoldingPayment"   style="overflow: auto;" class="example-card">
                <mat-card-header fxLayout="row">
                    <mat-card-title fxLayout="row">
                        <h3>פרטי החזקת ביהמ"ד לתשלום: [{{ payment.paymentID }}]</h3>
                        <span class="example-fill-remaining-space"></span>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="payment.paymentCommitments">
                        <div *ngFor="let pc of payment.paymentCommitments">
                            <h4 style="text-decoration: underline; text-align: center;margin-top:18px;"
                                *ngIf="payment.paymentCommitments.length > 1">
                                {{pc.commitmentTitle}} </h4>
                            <holdings-list-small [paymentCommitmentID]="pc.paymentCommitmentID">Loading...
                            </holdings-list-small>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card *ngIf="payment.paymentGroupID"   style="overflow: auto;" class="example-card">
                <mat-card-header fxLayout="row">
                    <mat-card-title fxLayout="row">
                        <h3>תשלומים נוספים בסידרה&nbsp;</h3>
                        <mat-icon matTooltip="קבוצת תשלומים"
                            [ngStyle]="{'color': servicesData.getRandomColor(payment.paymentGroupID)}">
                            linear_scale</mat-icon>
                        <span class="example-fill-remaining-space"></span>
                        <button style="font-size: 14px; background-color: navy; color: white; float: left;"
                            (click)="viwPayments()">הצג</button>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="showPayments">
                    <payments-short-list [paymentGroupID]="payment.paymentGroupID">Loading...
                    </payments-short-list>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxLayout="row" style="overflow: auto;" fxFlexAlign="start end">
            <mat-card *ngIf="payment.transactionView"   style="overflow: auto;" class="example-card">
                <mat-card-header fxLayout="row">
                    <mat-card-title fxLayout="row">
                        <h3>פרטי פעולת חיוב: {{ payment.transactionID }}</h3>
                            <span class="example-fill-remaining-space"></span>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content style="width: 100%;">
                        <table class="table table-striped no-margins">
                            <thead>
                                <tr>
                                    <th>יצירת רשומה ב:</th>
                                    <th>סכום</th>
                                    <th>תאריך ביצוע</th>
                                    <th>מזהה במסוף</th>
                                    <th>מס' אישור</th>
                                    <th>סטטוס</th>
                                    <th>אמצעי תשלום</th>
                                    <th>תגובת אשראי</th>
                                    <th>מס' נסי'</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ payment.transactionView.createDate | date: 'dd-MM-yyyy hh:mm' }}</td>
                                    <td>{{ payment.transactionView.amount | number}}
                                        {{ payment.transactionView.currencyIcon }}</td>
                                    <td>{{ payment.transactionView.executionDate }}</td>
                                    <td>{{ payment.transactionView.responseTransId }}</td>
                                    <td>{{ payment.transactionView.debitApproveNumber }}</td>
                                    <td>{{ payment.transactionView.statusResponseName }}</td>
                                    <td class="ltr text-right">
                                        <a
                                            [routerLink]="[ '', {outlets: {popup: [ 'payMethods', payment.transactionView.payMethodID]}}]">{{
                                            payment.transactionView.methodTitle }}</a>
                                    </td>
                                    <td>{{ payment.transactionView.messages }} {{ payment.transactionView.errors }}</td>
                                    <td>{{ payment.transactionView.severalAttempts }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
        </div>
    </div>
</div>
