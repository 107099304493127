import { fromEvent } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'contacts-search-input',
    templateUrl: 'contacts-search-input.component.html',
    styleUrls: ['contacts-search-input.component.scss']
})
export class ContactsSearchInput implements OnInit, AfterViewInit {

    @ViewChild('txtSearchInp') txtSearchInp: ElementRef;
    @Input('query') public query: FormControl; 
    @Output() onChangeTxt = new EventEmitter<string>();

    ngOnInit() { }
    ngAfterViewInit() {
        const keyup = fromEvent(this.txtSearchInp.nativeElement, "keyup")
            .pipe(debounceTime(400)
            )
            .subscribe((q) =>
                this.onChangeTxt.emit((<any>q).target.value)
            );
    }
}
